<div class="CTP-gdpr a-link block">
  <div class="privacy-title-text">
    {{ 'data-protection' | sharedTranslate }}
  </div>

  <ng-container *ngIf="loading">
    <div class="w-full p-5">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
        [count]="20"
      ></ngx-skeleton-loader>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div
      class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500"
      [innerHTML]="getPrimaryLegalText() | safeHtml"
    >
    </div>
    <div class="w-full flex justify-center">
      <div class="w-full md:max-w-[680px]">
        <ng-container *ngTemplateOutlet="dealerSelect"></ng-container>

        <p
          *ngIf="!showConsents"
          class="font-efSans text-base text-justify text-mat-ef-revolution-primary-500 my-2"
        >
          *
          <T
            str="Consents are currently unavailable, please try again later."
            key="customerPortal.customer-portal.gdpr.consents-unavailable"
            tags="customer-portal, 3.21"
          ></T>
        </p>

        <div
          *ngIf="showConsents"
          class="w-full md:max-w-[680px]"
        >
          <ng-container *ngIf="consentsResponse?.group">
            <div
            class="text-lg my-4 font-semibold text-mat-ef-revolution-primary-500"
            >
              <T
                str="Dealership"
                key="customerPortal.customer-portal.gdpr.dealership"
                tags="customer-portal, 3.21"
              ></T>
            </div>
            <ng-container
              *ngTemplateOutlet="
                consent;
                context: { consent: consentsResponse?.group, type: 'group' }
              "
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="consentsResponse?.dealers?.length > 0">
            <div
              class="text-lg my-4 font-semibold text-mat-ef-revolution-primary-500"
            >
              <T
                str="Dealership"
                key="customerPortal.customer-portal.gdpr.dealership"
                tags="customer-portal, 3.21"
              ></T>
            </div>
            <ng-container
              *ngFor="let d of consentsResponse?.dealers | orderBy: 'name'"
            >
              <ng-container
                *ngTemplateOutlet="
                  consent;
                  context: { consent: d, type: 'dealer' }
                "
              ></ng-container>
            </ng-container>
          </ng-container>

          <div *ngIf="consentsResponse?.brands?.length > 0">
            <div
              class="text-lg my-4 font-semibold text-mat-ef-revolution-primary-500"
            >
              <T
                str="Brands"
                key="customerPortal.customer-portal.gdpr.brands"
                tags="customer-portal, 3.21"
              ></T>
            </div>
            <ng-container
              *ngFor="let b of consentsResponse?.brands | orderBy: 'name'"
            >
              <ng-container
                *ngTemplateOutlet="
                  consent;
                  context: { consent: b, type: 'brand' }
                "
              ></ng-container>
            </ng-container>
          </div>
        </div>

        <div
          class="flex flex-row-reverse max-w-[1000px] mt-2.5 gap-4"
          *ngIf="showConsents"
        >
          <button
            mat-button
            mat-flat-button
            color="secondary"
            id="saveButton"
            class="revolution"
            (click)="updateConsents()"
            [disabled]="disabled || updates.length === 0"
          >
            <mat-spinner [diameter]="16" *ngIf="disabled"></mat-spinner
            ><span> {{ 'save' | sharedTranslate }}</span>
          </button>

          <button
            color="basic"
            class="revolution icon-button"
            mat-button
            mat-flat-button
            (click)="downloadPrintFile(true)"
            [disabled]="disabled || !consentsPartnerId"
          >
            <mat-icon svgIcon="visibility_on" class="cursor-pointer"></mat-icon>
          </button>
          <button
            color="basic"
            class="revolution icon-button"
            mat-button
            mat-flat-button
            (click)="downloadPrintFile()"
            [disabled]="disabled || !consentsPartnerId"
          >
            <mat-icon class="cursor-pointer" svgIcon="download"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template
  #checkbox
  let-consent="consent"
  let-type="type"
  let-channel="channel"
>
  <div class="relative">
    <mat-checkbox
      class="h-6 flex items-center"
      [disabled]="checkboxIsDisabled(consent, channel.name)"
      color="primary"
      [checked]="getConsentValue(consent, channel.name)"
      (change)="updateConsentValue(type, consent.id, channel.name, $event)"
    >
      <div class="text-sm">
        {{
          {
            str: channel.name,
            prefix: 'customerPortal.customer-portal.communication.'
          } | transifexTranslate
        }}
      </div>
    </mat-checkbox>
    <div
      *ngIf="checkboxIsDisabled(consent, channel.name)"
      class="absolute left-0 top-0 w-full h-full"
      (click)="errorMessage(channel.name)"
    ></div>
  </div>
</ng-template>

<ng-template #logo let-consent="consent">
  <img
    class="w-auto h-auto max-w-full max-h-full object-contain rounded m-auto"
    src="{{ consent?.logo ? consent.logo : 'assets/ef-logo.svg' }}"
    alt=""
    onerror="this.onerror=null; this.src='assets/ef-logo.svg'"
  />
</ng-template>

<ng-template #consent let-consent="consent" let-type="type">
  <div
    class="w-full rounded-md bg-mat-ef-revolution-grey-200 text-mat-ef-revolution-primary-500 p-4 md:p-6 flex flex-col mb-6 md:mb-12"
  >
    <div *ngIf="type === 'brand'" class="flex mb-3">
      <div class="w-8">
        <ng-container
          *ngTemplateOutlet="logo; context: { consent: consent }"
        ></ng-container>
      </div>
      <div class="ml-6 flex-1 font-semibold text-base">
        {{ consent.name }}
      </div>
    </div>

    <div class="flex">
      <div class="w-8">
        <ng-container *ngIf="type !== 'brand'">
          <ng-container
            *ngTemplateOutlet="logo; context: { consent: consent }"
          ></ng-container>
        </ng-container>
      </div>
      <div
        [ngClass]="{ 'ml-6': type !== 'brand', '-ml-1': type === 'brand' }"
        class="flex-1 md:ml-6 flex justify-between gap-2"
      >
        <div class="flex-1">
          <div class="font-semibold text-base leading-none">
            {{ consent.consents.name }}
          </div>
          <div
            *ngIf="type !== 'brand'"
            class="font-semibold text-sm leading-none mt-1.5"
          >
            {{ consent.partnerName }} {{ consent.partnerSubname }}
          </div>
          <div class="text-sm opacity-70 leading-none mt-1.5">
            {{ consent.street }}{{ consent.zip ? ', ' + consent.zip : '' }}
            {{ consent.city }}
          </div>
        </div>
        <div
          *ngIf="consent.consents?.signed"
          class="w-fit flex gap-1 text-sm -mt-[2px]"
        >
          <mat-icon class="text-green-500 w-5" svgIcon="create"></mat-icon>
          <div class="opacity-70">
            <T
              str="Signed"
              key="customerPortal.customer-portal.gdpr.signed"
              tags="customer-portal, 3.21"
            ></T>
          </div>
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="w-8 mr-6 hidden md:flex"></div>
      <div class="flex-1 flex flex-col">
        <div class="flex gap-1 mt-4">
          <div class="w-fit flex items-center">
            <mat-checkbox
              class="h-6 w-6 md:float-right"
              color="primary"
              [checked]="getConsentValue(consent, 'checked')"
              (change)="updateConsentValue(type, consent.id, 'checked', $event)"
            ></mat-checkbox>
          </div>
          <div
            class="text-sm leading-5 flex-1 max-w-[calc(100vw-90px)] md:max-w-[550px] break-words"
            [innerHTML]="consent.consents.legalText | safeHtml"
          ></div>
        </div>
        <div
          *ngIf="getConsentValue(consent, 'checked')"
          class="mt-4 md:mt-6 flex flex-wrap gap-4 md:gap-6 ml-7 md:ml-0"
        >
          <div
            *ngFor="let channel of consent.consents?.channels"
            class="flex gap-1 w-[45%] md:w-auto"
          >
            <ng-container
              *ngTemplateOutlet="
                checkbox;
                context: { consent: consent, type: type, channel: channel }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dealerSelect>
  <div *ngIf="hasConsentDealers" class="mt-8 w-full md:w-[680px] text-mat-ef-revolution-primary-500">
    <mat-form-field appearance="legacy" class="w-full">
      <mat-label>
        {{ getTranslation('select-dealer') }}
      </mat-label>
      <input
        type="text"
        required
        matInput
        [matAutocomplete]="auto"
        [formControl]="consentDealerControl"
        name="consentDealerControl"
        [placeholder]="getTranslation('select-dealer')"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="formatDealerName"
      >
        <mat-option
          class="text-mat-ef-revolution-primary-500"
          *ngFor="let dealer of filteredConsentDealers"
          [value]="dealer"
        >
          {{ formatDealerName(dealer) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-template>
