<div class="view-container">
  <ng-container *ngIf="showMobileView === false">
    <!-- general -->
    <div
      class="content-container"
      id="general"
      [hidden]="currentTab !== 'vehicleGeneral'"
      *ngIf="vehicles?.length"
    >
      <div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
        <div class="col-span-1 lg:col-span-7">
          <customer-portal-app-revolution-tyre-molecule [vehicle]="vehicle">
          </customer-portal-app-revolution-tyre-molecule>
          <customer-portal-app-revolution-vehicle-leasing-and-warranty
            [vehicle]="vehicle"
            [vin]="vehicle?.vin"
          >
          </customer-portal-app-revolution-vehicle-leasing-and-warranty>

          <customer-portal-app-revolution-vehicle-service-package-and-insurance
            [vehicle]="vehicle"
          >
          </customer-portal-app-revolution-vehicle-service-package-and-insurance>

          <customer-portal-app-revolution-trade-molecule [vehicle]="vehicle">
          </customer-portal-app-revolution-trade-molecule>
          <customer-portal-app-revolution-vehicle-reminders
            *ngIf="vehicle && sharedService.showRemindersForVehicle(vehicle)"
            [vehicle]="vehicle"
          ></customer-portal-app-revolution-vehicle-reminders>
        </div>
        <div class="col-span-1 flex flex-col gap-6 lg:col-span-3">
          <customer-portal-app-revolution-vehicle-last-action-service
            [vehicle]="vehicle"
          >
          </customer-portal-app-revolution-vehicle-last-action-service>
          <customer-portal-app-revolution-vehicle-online-shop-link
            *ngIf="hasVehicleOnlineShop"
          >
          </customer-portal-app-revolution-vehicle-online-shop-link>

          <customer-portal-app-revolution-nearest-fuel-station
            [vehicle]="vehicle"
            *ngIf="
              hasNearestFuelStation &&
              vehicle.fuelType &&
              (nearestFuelStationTypes | arrayContains: vehicle.fuelType)
            "
          >
          </customer-portal-app-revolution-nearest-fuel-station>

          <customer-portal-app-fuel-price-comparison
            *ngIf="hasFuelPriceComparison() && fuelPrices?.[0]"
            [vehicle]="vehicle"
          >
          </customer-portal-app-fuel-price-comparison>

          <customer-portal-app-faq-card
            *ngIf="hasFAQ"
            [alwaysSmall]="true"
          ></customer-portal-app-faq-card>
        </div>
      </div>
    </div>

    <!-- dealer -->
    <div
      class="content-container"
      id="dealer"
      [hidden]="currentTab !== 'vehicleDealer'"
    >
      <customer-portal-app-revolution-vehicle-dealers
        [setVehicle]="vehicle"
        [setUser]="user"
      >
      </customer-portal-app-revolution-vehicle-dealers>
    </div>

    <!-- details -->
    <div
      class="content-container"
      id="vehicle-details"
      [hidden]="currentTab !== 'vehicleDetails'"
      *ngIf="vehicles?.length"
    >
      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div class="col-span-1">
          <customer-portal-app-revolution-vehicle-basic-details
            [setVehicle]="vehicle"
          >
          </customer-portal-app-revolution-vehicle-basic-details>
        </div>

        <div class="col-span-1">
          <customer-portal-app-revolution-vehicle-extended-details>
          </customer-portal-app-revolution-vehicle-extended-details>
        </div>
      </div>
      <customer-portal-app-revolution-vehicle-equipment
        [loading]="dealerLoading"
      >
      </customer-portal-app-revolution-vehicle-equipment>
      <customer-portal-app-revolution-vehicle-energy [loading]="dealerLoading">
      </customer-portal-app-revolution-vehicle-energy>
    </div>

    <!-- vehicle history -->
    <div
      class="content-container"
      id="service"
      [hidden]="currentTab !== 'vehicleServiceHistory'"
    >
      <customer-portal-app-revolution-vehicle-documents
        [vehicle]="vehicle"
        *ngIf="vehicle && vehicle?.vin"
      >
      </customer-portal-app-revolution-vehicle-documents>
    </div>

    <!-- tracking -->
    <div
      *ngIf="vehicles?.length && hasTracking"
      class="content-container"
      id="tracking"
      [hidden]="currentTab !== 'vehicleTrackingHistory'"
    >
      <customer-portal-app-vehicle-track-trace
        [vehicle]="vehicle"
        *ngIf="vehicle && vehicle?.vin"
      >
      </customer-portal-app-vehicle-track-trace>
    </div>

    <div
      class="content-container"
      id="gallery"
      [hidden]="currentTab !== 'vehicleGallery'"
    >
      <customer-portal-app-vehicle-images
        [vehicle]="vehicle"
        *ngIf="vehicle && vehicle?.vin"
      >
      </customer-portal-app-vehicle-images>
    </div>
  </ng-container>

  <ng-container *ngIf="showMobileView === true">
    <div
      (touchstart)="swipe($event, 'start')"
      (touchend)="swipe($event, 'end')"
    >
      <mat-tab-group
        mat-stretch-tabs="false"
        [(selectedIndex)]="swipeData.currentTab"
        class="mat-tab-group_hide-header"
      >
        <mat-tab>
          <!-- general -->
          <div
            class="content-container"
            id="general"
            [hidden]="currentTab !== 'vehicleGeneral'"
            *ngIf="vehicles?.length"
          >
            <div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
              <div class="col-span-1 lg:col-span-7">
                <customer-portal-app-revolution-tyre-molecule
                  [vehicle]="vehicle"
                >
                </customer-portal-app-revolution-tyre-molecule>
                <customer-portal-app-revolution-vehicle-leasing-and-warranty
                  [vehicle]="vehicle"
                  [vin]="vehicle?.vin"
                >
                </customer-portal-app-revolution-vehicle-leasing-and-warranty>

                <customer-portal-app-revolution-vehicle-service-package-and-insurance
                  [vehicle]="vehicle"
                >
                </customer-portal-app-revolution-vehicle-service-package-and-insurance>
                <customer-portal-app-revolution-trade-molecule
                  [vehicle]="vehicle"
                >
                </customer-portal-app-revolution-trade-molecule>
                <customer-portal-app-revolution-vehicle-reminders
                  *ngIf="
                    vehicle && sharedService.showRemindersForVehicle(vehicle)
                  "
                  [vehicle]="vehicle"
                ></customer-portal-app-revolution-vehicle-reminders>
              </div>
              <div class="col-span-1 flex flex-col gap-6 lg:col-span-3">
                <customer-portal-app-revolution-vehicle-last-action-service
                  [vehicle]="vehicle"
                >
                </customer-portal-app-revolution-vehicle-last-action-service>
                <customer-portal-app-revolution-vehicle-online-shop-link
                  *ngIf="hasVehicleOnlineShop"
                >
                </customer-portal-app-revolution-vehicle-online-shop-link>

                <customer-portal-app-revolution-nearest-fuel-station
                  [vehicle]="vehicle"
                  *ngIf="
                    hasNearestFuelStation &&
                    vehicle.fuelType &&
                    (nearestFuelStationTypes | arrayContains: vehicle.fuelType)
                  "
                >
                </customer-portal-app-revolution-nearest-fuel-station>

                <customer-portal-app-fuel-price-comparison
                  *ngIf="hasFuelPriceComparison() && fuelPrices?.[0]"
                  [vehicle]="vehicle"
                >
                </customer-portal-app-fuel-price-comparison>

                <customer-portal-app-faq-card
                  *ngIf="hasFAQ"
                  [alwaysSmall]="true"
                ></customer-portal-app-faq-card>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <!-- details -->
          <div
            class="content-container"
            id="vehicle-details"
            [hidden]="currentTab !== 'vehicleDetails'"
            *ngIf="vehicles?.length"
          >
            <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
              <div class="col-span-1">
                <customer-portal-app-revolution-vehicle-basic-details
                  [setVehicle]="vehicle"
                >
                </customer-portal-app-revolution-vehicle-basic-details>
              </div>

              <div class="col-span-1">
                <customer-portal-app-revolution-vehicle-extended-details>
                </customer-portal-app-revolution-vehicle-extended-details>
              </div>
            </div>
            <customer-portal-app-revolution-vehicle-equipment
              [loading]="dealerLoading"
            >
            </customer-portal-app-revolution-vehicle-equipment>
            <customer-portal-app-revolution-vehicle-energy
              [loading]="dealerLoading"
            >
            </customer-portal-app-revolution-vehicle-energy></div
        ></mat-tab>
        <mat-tab
          ><!-- dealer -->
          <div
            class="content-container"
            id="dealer"
            [hidden]="currentTab !== 'vehicleDealer'"
          >
            <customer-portal-app-revolution-vehicle-dealers
              [setVehicle]="vehicle"
              [setUser]="user"
            >
            </customer-portal-app-revolution-vehicle-dealers>
          </div>
        </mat-tab>
        <mat-tab>
          <!-- vehicle history -->
          <div
            class="content-container"
            id="service"
            [hidden]="currentTab !== 'vehicleServiceHistory'"
          >
            <customer-portal-app-revolution-vehicle-documents
              [vehicle]="vehicle"
              *ngIf="vehicle && vehicle?.vin"
            >
            </customer-portal-app-revolution-vehicle-documents>
          </div>
        </mat-tab>
        <mat-tab>
          <div
            class="content-container"
            id="gallery"
            [hidden]="currentTab !== 'vehicleGallery'"
          >
            <customer-portal-app-vehicle-images
              [vehicle]="vehicle"
              *ngIf="vehicle && vehicle?.vin"
            >
            </customer-portal-app-vehicle-images></div
        ></mat-tab>
        <mat-tab
          *ngIf="
            hasTracking && vehicle?.createdByDataSource !== 'customer_portal'
          "
        >
          <!-- tracking -->
          <div
            *ngIf="vehicles?.length && hasTracking"
            class="content-container"
            id="tracking"
            [hidden]="currentTab !== 'vehicleTrackingHistory'"
          >
            <customer-portal-app-vehicle-track-trace
              [vehicle]="vehicle"
              *ngIf="vehicle && vehicle?.vin"
            >
            </customer-portal-app-vehicle-track-trace>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>
</div>
