<div id="vehicle-navigation" class="container py-14">
  <mat-card class="m-auto rounded-lg">
    <ng-container *ngTemplateOutlet="nav"></ng-container>
    <customer-portal-app-revolution-my-account-view></customer-portal-app-revolution-my-account-view>
  </mat-card>
</div>

<ng-template #nav>
  <nav
    mat-tab-nav-bar
    [tabPanel]="tabPanel"
    mat-stretch-tabs
    class="w-[calc(100%+32px)] -mx-4 md:w-full md:mx-auto ef-tab-nav-bar"
    [ngClass]="{
      'lg:w-10/12': navigationItems?.length > 3,
      'lg:w-1/2': !(navigationItems?.length > 3)
    }"
  >
    <a
      data-cy="user-navigation-tab"
      mat-tab-link
      class="min-w-fit"
      *ngFor="let i of navigationItems"
      (click)="sendContentModulesTabSwitchData(i.translationKey)"
      [routerLink]="i.pathWithParams"
      [routerLinkActive]="['active']"
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      <T
        [str]="i.name"
        [key]="i.translationKey"
        tags="customer-portal, notranslate"
      ></T>
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
</ng-template>
