<div id="vehicle-navigation" class="container py-14">
  <mat-card class="m-auto rounded-lg">
    <nav
      mat-tab-nav-bar
      [tabPanel]="tabPanel"
      mat-stretch-tabs
      class="w-[calc(100%+32px)] -mx-4 md:w-full md:mx-auto lg:w-1/2 ef-tab-nav-bar"
    >
      <a
        mat-tab-link
        class="min-w-fit"
        *ngFor="let i of navigationItems"
        (click)="sendContentModulesTabSwitchData(i.translationKey)"
        [routerLink]="i.pathWithParams"
        [routerLinkActive]="['active']"
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        <T
          [str]="i.name"
          [key]="i.translationKey"
          tags="customer-portal, notranslate"
        ></T>
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <customer-portal-app-revolution-marketplace-routes></customer-portal-app-revolution-marketplace-routes>
  </mat-card>
</div>
