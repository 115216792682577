import {
  Component,
  type OnDestroy,
  type OnInit,
  ViewChild
} from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import {
  DomSanitizer,
  type SafeResourceUrl,
  Title
} from '@angular/platform-browser'
import { NavigationEnd, ResolveEnd, Router } from '@angular/router'
import {
  type User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleMapsService,
  CptGoogleTagmanagerService,
  TransifexService,
  EcondaService,
  IconService,
  PwaService,
  IosMessagingService,
  MatomoIntegrationService,
  SharedService as CPTSharedService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'

import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslateService } from '@ngx-translate/core'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { type KeycloakTokenParsed } from '@emilfreydigital/keycloak-js'
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer'
import { NGXLogger } from 'ngx-logger'
import packageJson from '../../package.json'
import { SharedService } from './services/shared.service'
import { CarmatoService } from './services/carmato.service'
import { DashEcoService } from './services/dash-eco.service'
import { DataService } from './services/data.service'
import { HeaderFooterService } from './services/header-footer-service.service'
import { LocalStorageService } from './services/local-storage.service'
import { OneSignalService } from './services/oneSignal.service'
import { SideMenuService } from './services/side-menu.service'
import {
  type VehiclesResponse,
  VehiclesService
} from './services/vehicles.service'
import { LanguageService } from './services/language.service'
import { AppService } from './services/app.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { ConsentSnackbarComponent } from './components/basic/consent-snackbar/consent-snackbar.component'
import { MatSnackBar } from '@angular/material/snack-bar'

export interface CptKeycloakTokenParsed extends KeycloakTokenParsed {
  iss?: string
  azp?: string
}

declare const gtag: Function

@Component({
  selector: 'customer-portal-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppService]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') public sidenav: MatSidenav

  loggedIn = false
  newLogin = false

  user: User
  public isIos = false
  public isPwa = false
  public showIosAddHomeScreenBanner = true
  public appVersion = packageJson.version

  homepage: string
  public hasAppointments
  public hasMarketPlace
  public hasTracking
  public hasNewGDPR
  public country
  kcLanguageUpdate = false
  kcUpdateIframeSrc: SafeResourceUrl = null

  sub = {
    onUserVehiclesLoadedSub: null,
    onReloadUserDealers: null,
    onNewAppointmentAdded: null,
    onTransifexLanguageChange: null,
    transifexLanguageIsLoading: null,
    onActionClicked: null
  }

  appConfig: CustomerPortalConfig
  public pwaEnabled
  screenSize: SCREEN_SIZE
  showTransifexSpinner = true

  public hasMatomo
  constructor (
    private readonly title: Title,
    private readonly translate: TranslateService,
    private readonly transifexService: TransifexService,
    private readonly sidenavService: SideMenuService,
    public router: Router,
    private readonly dataService: DataService,
    private readonly vehiclesService: VehiclesService,
    private readonly logger: NGXLogger,
    private readonly gtmService: GoogleTagManagerService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly keyCloakService: KeycloakService,
    public sanitizer: DomSanitizer,
    public resizeService: ResizeService,
    private readonly dashEcoService: DashEcoService,
    private readonly carmatoService: CarmatoService,
    private readonly headerFooterService: HeaderFooterService,
    private readonly localStorage: LocalStorageService,
    private readonly sharedService: SharedService,
    private readonly cptSharedService: CPTSharedService,
    private readonly googleMapService: CptGoogleMapsService,
    private readonly econdaService: EcondaService,
    private readonly iconService: IconService,
    private readonly oneSignalService: OneSignalService,
    private readonly pwaService: PwaService,
    private readonly languageService: LanguageService,
    private readonly appService: AppService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>,
    private readonly iosMessagingService: IosMessagingService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly userService: UserService,
    private readonly snackBar: MatSnackBar
  ) {
    this.homepage = this.remoteConfigService.get('homepage')
    this.hasAppointments = this.remoteConfigService.get('hasAppointments')
    this.hasMarketPlace = this.remoteConfigService.get('hasMarketPlace')
    this.hasTracking = this.remoteConfigService.get('hasTracking')
    this.hasNewGDPR = this.remoteConfigService.get('hasNewGDPR')
    this.country = this.remoteConfigService.get('country.code')
    this.pwaEnabled = this.remoteConfigService.get('pwaEnabled')
    this.hasMatomo = this.remoteConfigService.get('matomo.enabled')

    this.appConfig = (window as any).ef.remoteConfig
      .configuration as CustomerPortalConfig

    this.isIos = this.pwaService.checkIos()
    this.isPwa = this.pwaService.checkPwa()

    this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
    })

    googleMapService.apiLoaded.subscribe(data => {
      this.googleMapService.onMapsChange(data)
    })
    this.appService.loadChatBot()

    this.languageService.setupLanguage()

    pdfDefaultOptions.assetsFolder = 'assets/pdfjs'
    pdfDefaultOptions.verbosity = 0

    this.sub.transifexLanguageIsLoading =
      this.transifexService.transifexLanguageIsLoading.subscribe(loading => {
        this.showTransifexSpinner = loading
      })

    // translate title
    this.sub.onTransifexLanguageChange =
      this.transifexService.onTransifexLanguageChange.subscribe(() => {
        const cPtitle = this.transifexService.getSharedTranslation(
          'emil-frey-customer-portal'
        )
        this.title.setTitle(cPtitle)
      })

    // collect analytics on page change
    this.router.events.subscribe(event => {
      if (event instanceof ResolveEnd && this.user != null) {
        this.econdaService.send({
          content: event.urlAfterRedirects
        })
        this.appService.sendGtmData(this.user)

        let urlAfterRedirects = event.urlAfterRedirects
        if (urlAfterRedirects === '/') {
          urlAfterRedirects = '/summary'
        }
        void this.matomoIntegrationService.trackPageView(urlAfterRedirects)
      }
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        })
        // if we are at / navigate to summary (home page)
        this.navigateToHomepage()
      }
    })

    // Subscribe to events which cause reloading vehicle data
    this.dataService.onVehicleVinChange.subscribe(v => {
      this.appService.vin = v
      if (this.appService.allVehicles != null) {
        const vehicle = this.appService.allVehicles.find(({ vin }) => vin === v)
        if (vehicle != null) {
          void this.dataService.loadVehicleData(vehicle)
        }
      } else {
        this.vehiclesService
          .getVehicles(true, false, !!this.hasTracking)
          .subscribe((allVehicles: VehiclesResponse) => {
            this.appService.allVehicles = allVehicles.userVehicles
            this.dataService.userVehiclesLoaded(this.appService.allVehicles)
          })
      }
    })

    this.dataService.onRefreshVehicleData.subscribe(vehicle => {
      void this.dataService.loadVehicleData(vehicle)
    })

    this.dataService.onLanguageChange.subscribe(lang => {
      this.updateKcLanguage(lang)
      // get new articles
      this.dashEcoService.setArticles(
        this.appService.allVehicles,
        this.appService.personalization,
        this.appService.userDealers
      )

      this.transifexService.setLanguage(
        lang,
        this.remoteConfigService.get('transifexConfig.transifexLanguages'),
        this.remoteConfigService.get('country.code')
      )
    })

    this.sub.onUserVehiclesLoadedSub =
      this.dataService.onUserVehiclesLoaded.subscribe(vehicles => {
        this.vehiclesService.setVehicleDealer(
          vehicles,
          this.appService.userDealers
        )
        this.appService.allVehicles = vehicles
        void this.localStorage.setVehicles(vehicles)
      })

    // if dealers changed on any vehicle, have to get all dealers again
    this.sub.onReloadUserDealers =
      this.dataService.onReloadUserDealers.subscribe(() => {
        void this.appService.getUserDealers(true)
      })

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user != null ? user : null
      if (user != null) {
        this.appService.onUserLoaded(user)
        // after user is loaded check matomo consent
        const openConsentSnackbar =
          this.matomoIntegrationService.shouldOpenConsentSnackbar(user)
        if (openConsentSnackbar && this.newLogin === true) {
          this.openConsentSnackbar(user.contactId)
        }

        // ios - no consent needed
        const iosNative = this.pwaService.checkIosNative()
        if (user.trackingConsent === true || iosNative === true) {
          this.matomoIntegrationService.initialize(user.contactId)
        }
      }
    })

    if (this.hasAppointments) {
      this.sub.onNewAppointmentAdded =
        this.dataService.onNewAppointmentAdded.subscribe(() => {
          this.appService.getUpcomingAppointments()
        })
    }

    // Ios notification service setup
    this.iosMessagingService.setIosPushNotificationListener()
    this.iosMessagingService.iosNotification.subscribe(e => {
      const link = e.data.link
      this.logger.info(link)
      this.router.navigate([`/${String(link)}`])
    })
  }

  ngOnInit (): void {
    // Register icons
    this.iconService.registerIcons()

    this.pwaService.checkPwa()

    this.logger.info('Is this pwa? ' + JSON.stringify(this.pwaService.isPwa))

    // eslint-disable-next-line no-console
    console.log('Customer portal version: ' + this.appVersion)

    gtag('config', this.remoteConfigService.get('googleAnalytics.id'), {
      send_page_view: false
    })
    this.translate.onLangChange.subscribe(lang => {
      this.logger.info('Lang: ' + lang.lang)
    })

    const isLoggedin = this.keyCloakService.isLoggedIn()

    if (isLoggedin) {
      this.loggedIn = true
      // If session state is different than existing, user logged in:
      const currentSession = this.localStorage.getSessionState()
      const kc = this.keyCloakService.getKeycloakInstance()
      const sessionId = kc.sessionId
      const cpId = kc.tokenParsed.cp_id
      const notEditable = kc.tokenParsed.not_editable
      this.userService.notEditableChanged(notEditable)
      this.newLogin = false
      if (sessionId !== currentSession) {
        this.localStorage.setSessionState(kc.sessionId)
        this.logger.info('New login! ' + String(sessionId))
        this.cptGtmService.sendLoginSuccessData()
        this.iosMessagingService.login(cpId)
        this.newLogin = true
      }
      // Init oneSignal service
      if (
        this.pwaEnabled
        // !window?.location?.origin?.includes('http://localhost:4200')
      ) {
        this.oneSignalService.init()
      }

      // Get user data
      this.dataService.getUser()
      if (this.country === 'de' || this.hasNewGDPR === true) {
        this.dataService.getConsents()
      }
      if (this.hasNewGDPR === true) {
        this.dataService.getConsentDealers()
      }

      if (this.hasMarketPlace) {
        if (this.country === 'de') {
          this.carmatoService.getWatchlist().subscribe(favorites => {
            this.carmatoService.watchListLoaded(favorites)
          })
        }
      }
    } else {
      this.router.navigate(['/login']).catch(err => {
        this.logger.log(err)
      })
    }

    void this.gtmService.addGtmToDom()

    this.sidenavService.setSidenav(this.sidenav)

    this.languageService.setTransifexLanguage()

    this.headerFooterService.getHeaderFooter()

    // Listen to "login" event from keycloak iframe
    window.addEventListener('message', e => {
      if (e?.data.name === 'keycloak-message') {
        if (e.data.message === 'AuthenticationSuccess') {
          this.loggedIn = true
          this.navigateToHomepage()
        }
      }
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  kcUpdateIframeLoaded (): void {
    this.kcLanguageUpdate = false
  }

  updateKcLanguage (lang: string): void {
    this.kcLanguageUpdate = true
    const kc = this.keyCloakService.getKeycloakInstance()
    const token = kc.tokenParsed as CptKeycloakTokenParsed
    const issUrl: string = token.iss
    const clientId: string = token.azp
    const origin = window.location.origin
    const url =
      issUrl +
      '/protocol/openid-connect/update-locale-iframe.html?client_id=' +
      clientId +
      '&origin=' +
      origin +
      '&kc_locale=' +
      lang
    this.kcUpdateIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  navigateToHomepage (): void {
    if (this.router.url === '/' && this.loggedIn) {
      this.router.navigate([this.homepage]).catch(err => {
        this.logger.log(err)
      })
    }
  }

  hideIosAddHomeScreenBanner (): void {
    this.showIosAddHomeScreenBanner = false
  }

  openConsentSnackbar (cpId): void {
    this.cptSharedService.snackBarRef = this.snackBar.openFromComponent(
      ConsentSnackbarComponent,
      {
        panelClass: 'ef-snackbar',
        duration: 0
      }
    )
    this.cptSharedService.snackBarRef.afterDismissed().subscribe(() => {
      this.cptSharedService.snackBarRef = undefined
    })
    this.sub.onActionClicked = this.cptSharedService.snackBarRef?.instance?.onActionClicked?.subscribe(
      (action: 'accept' | 'decline') => {
        this.userService.changeUserPreference({
          name: 'trackingConsent',
          value: action === 'accept' ? 'true' : 'false' // has to be string
        })
          .subscribe({
            next: res => {
              if (action === 'accept') {
                if (this.matomoIntegrationService.matomoIsInitialized) {
                  this.matomoIntegrationService.startTracking(cpId)
                } else {
                  this.matomoIntegrationService.initialize(cpId)
                }
              } else {
                this.matomoIntegrationService.stopTracking()
              }
            },
            error: error => {
              this.logger.debug(error)
            }
          })
      }
    )
  }
}
